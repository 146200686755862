import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import {
  AuthorInfoField,
  Layout,
  StyledLink,
  TimestampInfoField
} from "../../components"

import { dateString } from "../../utils/helper"

import styles from "./index.module.css"

export default ({ data }) => {
  const story = data.contentfulCineStory
  const author = story.author

  const stories = data.allContentfulCineStory.edges
  const index = stories.findIndex((s) => s.node.url === story.url)
  const previousIndex = (index - 1 + stories.length) % stories.length
  const nextIndex = (index + 1) % stories.length

  const previousStory = stories[previousIndex]
  const nextStory = stories[nextIndex]

  return (
    <Layout>
      <div className={styles.page}>
        <div className={styles.container}>
          <div className={styles.title}>
            <h1>{story.title}</h1>
          </div>

          <div className={styles.info}>
            <TimestampInfoField
              createdAt={dateString(story.createdAt)}
              updatedAt={story.updatedAt && dateString(story.updatedAt)}
            />
            <AuthorInfoField name={author.name} />
          </div>
        </div>

        <div className={styles.mainText}>
          <ReactMarkdown escapeHtml={false} source={story.text.text} />
        </div>
      </div>
      <div className={styles.navigation}>
        <StyledLink
          href={`/story/${previousStory.node.url}`}
          label={`< ${previousStory.node.title}`}
        />
        <StyledLink
          href={`/story/${nextStory.node.url}`}
          label={`${nextStory.node.title} >`}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CineStoryByUrl($url: String!) {
    contentfulCineStory(url: { eq: $url }) {
      id
      url
      title
      text {
        text
      }
      author {
        name
      }
      createdAt
    }
    allContentfulCineStory(sort: { fields: createdAt, order: ASC }) {
      edges {
        node {
          id
          url
          title
        }
      }
    }
  }
`
